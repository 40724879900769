const Logo = ({ w }) => {
  return (
    <svg
      width="173"
      height="38"
      viewBox="0 0 173 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_194_2007)">
        <path
          d="M45.1607 12.9672C45.1703 12.0977 45.3865 11.2426 45.7918 10.4709C46.1863 9.64076 46.7609 8.90681 47.4749 8.32126C48.3113 7.72643 49.2332 7.25894 50.2098 6.9344C51.3818 6.51851 52.6119 6.2846 53.8563 6.24097C55.1247 6.27387 56.3796 6.508 57.5729 6.9344C58.4483 7.16528 59.278 7.54036 60.0273 8.04389V12.5512H59.6767C58.9862 11.9488 58.1732 11.4995 57.2924 11.2337C56.2171 10.8359 55.0741 10.6476 53.9264 10.6789C52.9183 10.6075 51.9129 10.85 51.0513 11.3724C50.7586 11.5619 50.5166 11.8184 50.3457 12.1202C50.1748 12.4221 50.08 12.7602 50.0695 13.1059C50.0262 13.4513 50.0262 13.8007 50.0695 14.1461C50.2166 14.4062 50.4061 14.6405 50.6305 14.8395L51.6123 15.4636L53.0148 16.0183L56.1003 17.0585C57.787 17.5975 59.3074 18.552 60.5182 19.8322C61.4883 21.1078 61.9837 22.6753 61.9207 24.2702C61.9365 25.2981 61.7209 26.3165 61.2896 27.2519C60.8935 28.159 60.294 28.9652 59.5365 29.6096C58.679 30.3022 57.7061 30.8419 56.6613 31.2045C55.4349 31.5787 54.158 31.7657 52.8745 31.7592C51.5964 31.759 50.3237 31.5959 49.0877 31.2738C47.854 30.9585 46.6729 30.468 45.5815 29.8176V25.241H46.0022C46.3982 25.574 46.8202 25.8755 47.2645 26.1424L48.9475 26.8359L50.7708 27.3213C51.4 27.39 52.0349 27.39 52.6642 27.3213C53.8642 27.465 55.0776 27.1956 56.1003 26.5585C56.4394 26.3072 56.7165 25.9833 56.9108 25.611C57.1051 25.2387 57.2116 24.8277 57.2223 24.4088C57.2453 24.0223 57.1817 23.6354 57.0363 23.2759C56.8909 22.9163 56.667 22.5929 56.3808 22.3286C55.64 21.7075 54.8136 21.1938 53.9264 20.803L50.8409 19.6935C50.0858 19.4413 49.3576 19.1161 48.667 18.7227C47.9984 18.3444 47.385 17.8778 46.8437 17.3359C46.327 16.7681 45.9226 16.1095 45.6516 15.3943C45.3239 14.6263 45.1569 13.8007 45.1607 12.9672Z"
          fill="#F6F6F6"
        />
        <path
          d="M63.8843 25.7957C63.868 24.901 64.0853 24.0173 64.5154 23.23C64.9205 22.4843 65.497 21.843 66.1985 21.3577C66.9249 20.785 67.7592 20.3607 68.6529 20.1095C69.656 19.8261 70.6951 19.686 71.7384 19.6935H73.4915L74.894 20.1095V19.4161C74.9036 19.0311 74.8339 18.6481 74.6893 18.2905C74.5446 17.9329 74.328 17.6081 74.0525 17.3358C73.243 16.6937 72.2093 16.3945 71.1774 16.5037C70.1765 16.5182 69.1836 16.6818 68.2321 16.9891C67.5204 17.218 66.8555 17.5702 66.2686 18.0292V13.8687H66.8997L68.3022 13.3833H70.0554H71.9488C73.0157 13.366 74.0793 13.5062 75.1044 13.7993C76.0065 14.029 76.8451 14.4555 77.5588 15.0475C78.2287 15.6028 78.7774 16.287 79.1717 17.0584C79.5494 17.9351 79.7404 18.8793 79.7327 19.8322V31.1351H75.1745V29.7482C74.6158 30.2958 73.9468 30.7211 73.211 30.9964C72.2437 31.4331 71.1885 31.6465 70.1255 31.6205C69.3582 31.7315 68.5786 31.7315 67.8113 31.6205C67.0758 31.365 66.3885 30.99 65.7777 30.511C65.1975 30.0046 64.7214 29.3925 64.3752 28.7081C63.9701 27.7925 63.8015 26.7917 63.8843 25.7957ZM68.5126 25.449C68.5069 25.7729 68.5805 26.0933 68.727 26.3831C68.8735 26.6729 69.0887 26.9233 69.3541 27.1132C69.9402 27.4829 70.6225 27.6756 71.3176 27.6679C72.0726 27.6956 72.8218 27.5283 73.4915 27.1825C74.0222 26.9913 74.5019 26.683 74.894 26.2811V23.3687H73.9824C73.4474 23.2937 72.9045 23.2937 72.3695 23.3687C71.3715 23.313 70.3771 23.5288 69.4944 23.9927C69.2366 24.1538 69.016 24.3667 68.8468 24.6176C68.6777 24.8685 68.5638 25.1517 68.5126 25.449Z"
          fill="#F6F6F6"
        />
        <path
          d="M82.0469 17.1277V13.1751H85.6934V12.4124C85.5736 10.8107 86.0198 9.21762 86.9557 7.90506C87.5202 7.39619 88.1862 7.00988 88.9109 6.77102C89.6356 6.53216 90.4028 6.44604 91.1632 6.5182C91.8392 6.44706 92.5209 6.44706 93.1969 6.5182H94.5994V10.3321H93.7579H91.7943C91.5388 10.2919 91.2785 10.2919 91.023 10.3321C90.7874 10.4734 90.6127 10.6956 90.5321 10.9562C90.4454 11.321 90.4454 11.7008 90.5321 12.0656V12.7591H95.5811V16.7116H90.462V30.5802H85.6934V16.7116L82.0469 17.1277Z"
          fill="#F6F6F6"
        />
        <path
          d="M96.5629 22.259C96.5499 20.9839 96.7635 19.7165 97.194 18.5145C97.6395 17.3956 98.2804 16.3629 99.0874 15.4634C99.8888 14.5968 100.869 13.911 101.963 13.4524C103.088 12.9542 104.306 12.6944 105.539 12.6897C106.768 12.6574 107.989 12.8942 109.115 13.3831C110.166 13.8454 111.1 14.5322 111.85 15.394C112.579 16.3263 113.126 17.3844 113.463 18.5145C113.894 19.836 114.107 21.2174 114.094 22.6057V23.1605C114.061 23.2973 114.061 23.4397 114.094 23.5765V23.9926H101.191C101.264 24.5174 101.445 25.0218 101.722 25.475C101.999 25.9282 102.368 26.3204 102.804 26.6276C103.885 27.3877 105.198 27.7552 106.521 27.6678C107.66 27.6631 108.793 27.4997 109.887 27.1824C110.723 26.8646 111.503 26.4201 112.201 25.8649H112.622V29.8867C111.779 30.4375 110.857 30.8584 109.887 31.1349C108.586 31.4929 107.239 31.6565 105.89 31.6203C104.547 31.6516 103.211 31.4158 101.963 30.9269C100.835 30.4876 99.8095 29.8271 98.9472 28.9853C98.0826 28.1703 97.4119 27.1755 96.9836 26.0729C96.6246 24.8346 96.4823 23.5447 96.5629 22.259ZM101.121 20.8028H109.536C109.536 19.7701 109.134 18.7769 108.414 18.0291C108.041 17.6549 107.591 17.3627 107.096 17.1716C106.601 16.9804 106.07 16.8945 105.539 16.9196C104.499 16.9246 103.49 17.2655 102.664 17.8904C102.214 18.241 101.844 18.6817 101.578 19.1836C101.312 19.6855 101.156 20.2373 101.121 20.8028Z"
          fill="#F6F6F6"
        />
        <path
          d="M116.268 13.8687L117.11 10.1935L120.896 11.0256L121.878 6.37964L126.577 7.41978L125.595 11.9964L129.943 12.8979L129.171 16.5731L124.823 15.6716L123 24.1315V24.5475C122.989 24.7305 123.021 24.9136 123.094 25.0821C123.167 25.2507 123.279 25.4 123.421 25.5183C123.739 25.758 124.094 25.9453 124.473 26.0731H125.735H126.787L125.945 29.8869H123.421C122.53 29.7621 121.659 29.5292 120.826 29.1935C120.071 28.8539 119.382 28.3843 118.793 27.8066C118.267 27.1328 117.995 26.2995 118.021 25.449C117.986 25.0336 117.986 24.6162 118.021 24.2008C118.055 23.8086 118.055 23.4142 118.021 23.022L119.915 14.3541L116.268 13.8687Z"
          fill="#FFC10A"
        />
        <path
          d="M128.891 28.3614L132.607 10.8176L136.815 11.7884L136.464 13.8687C136.998 13.4636 137.589 13.1366 138.217 12.8979C138.855 12.5647 139.541 12.3308 140.251 12.2045C140.831 12.1101 141.424 12.1101 142.004 12.2045C142.581 12.3369 143.145 12.5226 143.687 12.7592L142.705 17.1972C142.15 16.9007 141.561 16.668 140.952 16.5038C140.346 16.447 139.735 16.447 139.129 16.5038H137.516C136.988 16.6284 136.503 16.892 136.114 17.2665L133.238 29.3322L128.891 28.3614Z"
          fill="#FFC10A"
        />
        <path
          d="M142.916 28.2919L146.703 10.7481L151.331 11.7189L147.614 29.2627L142.916 28.2919ZM147.264 6.93422C147.269 6.28978 147.519 5.67091 147.965 5.20065C148.183 4.93145 148.461 4.7161 148.777 4.57167C149.094 4.42723 149.44 4.35768 149.788 4.36853C150.525 4.37349 151.239 4.61708 151.822 5.06196C152.101 5.30197 152.322 5.60127 152.468 5.93749C152.613 6.27371 152.68 6.63815 152.663 7.00357C152.656 7.66821 152.407 8.30801 151.962 8.80649C151.727 9.06853 151.434 9.27359 151.105 9.40585C150.777 9.5381 150.422 9.59401 150.069 9.56926C149.328 9.59321 148.604 9.34634 148.035 8.87583C147.751 8.64356 147.53 8.34344 147.395 8.00376C147.26 7.66407 147.215 7.296 147.264 6.93422Z"
          fill="#FFC10A"
        />
        <path
          d="M151.051 32.6606L156.1 9.29199L160.237 10.1241L159.887 11.719C160.458 11.3087 161.099 11.0034 161.78 10.8175C162.456 10.7503 163.137 10.7503 163.814 10.8175C166.245 10.8544 168.573 11.7952 170.335 13.4526C171.171 14.2916 171.837 15.2799 172.299 16.365C172.746 17.4924 172.984 18.6905 173 19.9015C172.984 21.18 172.746 22.4464 172.299 23.646C171.84 24.8157 171.175 25.8957 170.335 26.8358C169.477 27.7041 168.482 28.43 167.39 28.9854C166.184 29.5469 164.866 29.8313 163.533 29.8175H161.85C161.247 29.696 160.659 29.51 160.097 29.2628C159.501 28.8425 158.979 28.327 158.554 27.7372C158.05 27.4443 157.6 27.0692 157.222 26.6278L155.749 33.5621L151.051 32.6606ZM158.063 22.8832C158.29 23.2703 158.601 23.6021 158.975 23.854L160.167 24.6168C160.613 24.8186 161.085 24.9586 161.57 25.0329H162.832C163.584 25.0287 164.325 24.863 165.006 24.5475C165.645 24.2768 166.219 23.875 166.689 23.3686C167.121 22.818 167.475 22.2114 167.741 21.5657C167.876 20.8554 167.876 20.1264 167.741 19.4161C167.756 18.6781 167.613 17.9453 167.32 17.2664C167.054 16.6682 166.673 16.1266 166.198 15.6716C165.68 15.2423 165.085 14.913 164.445 14.7007C163.755 14.4224 163.016 14.281 162.271 14.2847C161.782 14.2173 161.287 14.2173 160.798 14.2847C160.271 14.3543 159.766 14.5442 159.326 14.8394L158.063 22.8832Z"
          fill="#FFC10A"
        />
        <path
          d="M37.6573 7.69708L19.7052 -5.79765e-06C19.5621 -0.101602 19.3904 -0.15625 19.2143 -0.15625C19.0381 -0.15625 18.8665 -0.101602 18.7234 -5.79765e-06L0.771229 7.69708C0.537909 7.79439 0.339581 7.95884 0.202016 8.16907C0.0644517 8.37929 -0.0059891 8.62558 -0.000152852 8.87591C0.145735 16.1387 2.0196 23.2655 5.46965 29.6788C5.86254 30.6429 6.38118 31.5521 7.01241 32.3832C8.43479 34.1738 10.2583 35.6132 12.3393 36.5879C14.4203 37.5626 16.7018 38.046 19.0039 38C21.3161 38.0461 23.6078 37.5631 25.7001 36.5888C27.7924 35.6145 29.6287 34.1752 31.0655 32.3832C31.7057 31.615 32.2914 30.8041 32.8187 29.9562C36.2272 23.5304 38.0759 16.4081 38.2183 9.15328C38.3057 8.88362 38.2978 8.59259 38.1959 8.32797C38.0939 8.06336 37.904 7.84088 37.6573 7.69708Z"
          fill="#F6F6F6"
        />
        <path
          d="M26.928 9.29199C24.8693 7.29305 22.0994 6.1731 19.2142 6.1731C16.3289 6.1731 13.559 7.29305 11.5003 9.29199C10.4889 10.2732 9.68553 11.444 9.13708 12.7358C8.58864 14.0276 8.30615 15.4146 8.30615 16.8157C8.30615 18.2168 8.58864 19.6038 9.13708 20.8956C9.68553 22.1875 10.4889 23.3582 11.5003 24.3394L19.2142 31.8285L26.928 24.3394C27.9394 23.3582 28.7428 22.1875 29.2912 20.8956C29.8397 19.6038 30.1221 18.2168 30.1221 16.8157C30.1221 15.4146 29.8397 14.0276 29.2912 12.7358C28.7428 11.444 27.9394 10.2732 26.928 9.29199ZM19.2142 20.3869C18.7647 20.3914 18.3196 20.3009 17.9084 20.1215C17.4972 19.9422 17.1295 19.678 16.8299 19.3467C16.5161 19.0375 16.2671 18.6701 16.0972 18.2655C15.9273 17.8609 15.8399 17.4271 15.8399 16.9891C15.8399 16.551 15.9273 16.1172 16.0972 15.7126C16.2671 15.3081 16.5161 14.9406 16.8299 14.6314C17.1536 14.35 17.5321 14.1371 17.9422 14.006C18.3522 13.8748 18.7851 13.828 19.2142 13.8686C19.6728 13.8713 20.1262 13.9647 20.5477 14.1433C20.9692 14.3219 21.3503 14.5822 21.6685 14.9088C22.2699 15.5499 22.604 16.392 22.604 17.2664C22.604 18.1408 22.2699 18.983 21.6685 19.6241C20.9812 20.1831 20.1013 20.4565 19.2142 20.3869Z"
          fill="#FFC10A"
        />
      </g>
      <defs>
        <clipPath id="clip0_194_2007">
          <rect width="173" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
